import React from 'react';

import Helmet from "react-helmet"
import { withPrefix, Link } from "gatsby"

import Axios from 'axios';

const queryString = require('query-string');

class Quote extends React.Component {

    state = {
        loaded: false,
        quote: {}
    }

    async componentDidMount() {

        const queryparams = queryString.parse(this.props.location.search);
        const uid = queryparams.uid;
        const { data } = await Axios.get('/api/quote/' + uid);
        this.setState({ loaded: true, quote: data });
    }

    render() {

        // console.log(this.props); 

        const { t } = this.props;

        return (
            <>
                <div className="container survey-page">
                    <div className="row mt-4 mb-1">
                        <div className="col-12">
                            <h1>Booking</h1>
                        </div>
                        <div className="col-12">
                            <h6>Help us select the best service professional by answering a few more questions</h6>
                        </div>
                    </div>
                    <div className="row mt-1 mb-5 ty-breadcrumb">
                        <div className="col-12 p-0">
                            <div className="d-flex justify-content-start align-items-center">
                                <div className="mr-3">
                                    {this.state.quote.category}
                                </div>
                                /
                                <div className="mr-3 ml-3">
                                    {this.state.quote.brand}
                                </div>
                                /
                                <div className="mr-3 ml-3">
                                    {this.state.quote.model}
                                </div>
                                /
                                <div className="mr-3 ml-3">
                                    {this.state.quote.service}
                                </div>
                                /
                                <div className="mr-3 ml-3">
                                    Booking #{this.state.quote.uid}
                                </div>
                                <div className="mr-3 ml-3 f-r-q desktop">
                                {t('Not what you are looking for')} <a href="/">{t('Search Again')}</a>
                                </div>
                            </div>
                            <div className="mr-3 ml-3 f-r-q mobile">
                                {t('Not what you are looking for')} <a href="/">{t('Search Again')}</a>
                                </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-5 bordered-body-grey">
                        <iframe 
                            id="surveyIframe" 
                            title="survey" 
                            src={this.state.quote.survey_url} 
                            align="center" 
                            style={{ margin: '0 auto', border: '0', width: '100%', height: '70vh' }}
                        />
                    </div>
                    <Helmet>
                        <script src={withPrefix('iframeResizer.min.js')} type="text/javascript" />
                        <script src={withPrefix('custom.js')} type="text/javascript" />
                    </Helmet>
                </div>
            </>
        )
    }
}

export default Quote;
